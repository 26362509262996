import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';

import TodayLogo from 'components/TodayLogo';
import { EventEmitter } from 'events';
import Modal from '../../Modal';
import SignupInput from '../SignupInput';

import styles from './styles.module.scss';
import { pickSiteKey } from '../verticalLogic';

const signupSuccessText = (email) => ({
  title: 'You have successfully signed up.',
  // eslint-disable-next-line max-len
  description: `${email} has subscribed to the newsletter. Check your inbox for a confirmation email.`,
});

const getIcon = (vertical) => {
  if (vertical === 'today') {
    return <TodayLogo textColor="black" sunriseColor="#FF503C" />;
  }

  const iconClasses = classNames('icon', {
    'icon-nbcnews-white': vertical === 'news',
  });

  return <span className={iconClasses} />;
};

const mapStateToProps = ({ shared }, { vertical }) => ({
  vertical: vertical || shared.vertical,
});

@connect(mapStateToProps)
class PopupModule extends React.Component {
  static propTypes = {
    vertical: PropTypes.string,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
  };

  static defaultProps = {
    vertical: 'news',
  };

  constructor(props) {
    super(props);
    this.state = {
      hasSignedUp: false,
      isOpen: true,
      moduleType: null,
      userEmail: '',
      hasReceivedSignup: false,
    };
  }

  componentDidMount() {
    const setModuleEvent = new EventEmitter();
    const { cookies } = this.props;
    const cookieValue = true;
    const numSecondsInDay = 86400;
    let cookieName;

    setModuleEvent.on('setModule', (moduleType) => {
      this.setState({ moduleType });
      cookieName = moduleType.newsletterId;
      if (cookies && !cookies.get(cookieName)) {
        cookies.set(cookieName, cookieValue, { maxAge: numSecondsInDay * 7 });
      } else {
        this.setState({ hasReceivedSignup: true });
      }
    });
    window.setModuleEvent = setModuleEvent;
  }

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  onSubmit = (email) => {
    this.setState({
      hasSignedUp: true,
      userEmail: email,
    });

    document.dispatchEvent(new Event('emailSignup'));
  };

  render() {
    const { vertical } = this.props;
    const {
      hasSignedUp, isOpen, moduleType, userEmail, hasReceivedSignup,
    } = this.state;
    const moduleInfo = hasSignedUp ? signupSuccessText(userEmail) : moduleType;

    const siteKey = pickSiteKey(vertical);

    const defaultSignupSource = 'popup-mod';

    return moduleType && !hasReceivedSignup ? (
      <Modal>
        <div
          id="email-popup-module"
          className={classNames(
            'fixed h-100 w-100 top0 left0 justify-center items-center df',
            styles.opaqueBackground,
            { 'v-hidden': !isOpen },
          )}
        >
          <div className={classNames('relative', styles.container)}>
            <div className={classNames('relative', styles.content)}>
              <button
                className="absolute top1 right1 f7 b-none bg-white"
                onClick={this.closeModal}
                type="button"
              >
                &#10005;
              </button>
              {getIcon(vertical)}
              <h2 className="f8 mv3">{moduleInfo.title}</h2>
              <h4 className="publico-txt fw1 lh-copy mt0 mb4">{moduleInfo.description}</h4>
              <SignupInput
                newsletterId={moduleType.newsletterId}
                onSubmit={this.onSubmit}
                signupSource={moduleType.signupSource || defaultSignupSource}
                sitekey={siteKey}
                vertical={vertical}
              />
              <section className={classNames('absolute', styles.dots)} />
            </div>
          </div>
        </div>
      </Modal>
    ) : null;
  }
}

export default withCookies(PopupModule);
