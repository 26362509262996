import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  image as ImagePropType,
  video as VideoPropType,
} from 'lib/CustomPropTypes';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import { Picture } from 'components/Picture';

import styles from './styles.module.scss';

const imageFlavors = {
  s: AIMS_FLAVORS.FIT_760w,
  m: AIMS_FLAVORS.FIT_1000w,
  l: AIMS_FLAVORS.FIT_760w,
  xl: AIMS_FLAVORS.FIT_1240w,
};

export function MainMedia({
  className,
  primaryMedia,
}) {
  if (!primaryMedia) return null;

  const mainMedia = (
    primaryMedia?.type === 'embeddedImage'
    && (
      <>
        <figure aria-labelledby="duet-main-media-caption" data-testid="article-duet__main-media-figure">
          <Picture
            preload
            loadingLazy={false}
            url={primaryMedia?.image?.url?.primary}
            alt={primaryMedia?.image?.url?.primary}
            flavor="fit"
            responsiveFlavors={imageFlavors}
            sizes={imageFlavors}
            originalHeight={primaryMedia?.image?.height}
            originalWidth={primaryMedia?.image?.width}
          />
        </figure>
      </>
    )
  );

  return (
    <div className={classNames(styles.mainMedia, className)} data-testid="article-duet__main-media">
      {mainMedia}
    </div>
  );
}

MainMedia.propTypes = {
  className: PropTypes.string,
  primaryMedia: PropTypes.oneOfType([ImagePropType, VideoPropType]),
};

MainMedia.defaultProps = {
  className: undefined,
  primaryMedia: null,
};
