import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getTimestampText } from 'lib/getTimestampText';

import { article as ArticlePropType } from 'lib/CustomPropTypes';

import { Unibrow } from 'components/Unibrow';
import LiveFlag from 'components/LiveFlag';

import styles from './styles.module.scss';


export function Heading({ className, article, vertical }) {
  const {
    headline,
    dek,
    unibrow,
    breakingNews,
    datePublished,
    dateCreated,
    flag,
  } = article;
  const { t } = useTranslation();
  return (
    <div className={classNames(className, styles.heading)}>
      {breakingNews ? (
        <LiveFlag
          isBreaking
          label={t(flag)}
          timestampWithUpdatedText={getTimestampText(datePublished, dateCreated, vertical)}
        />
      ) : null}
      <Unibrow
        size="div"
        className={styles.eyebrow}
        unibrow={unibrow}
      />
      <h1 className={styles.headline}>{(headline.primary)}</h1>
      {dek && <p className={styles.dek} data-test="article-dek">{dek}</p>}
    </div>
  );
}

Heading.propTypes = {
  article: ArticlePropType.isRequired,
  className: PropTypes.string,
  vertical: PropTypes.string.isRequired,
};

Heading.defaultProps = {
  className: undefined,
};
