import React, { Component } from 'react';
import classnames from 'classnames';
import { EventEmitter } from 'events';
import TodayLogo from 'components/TodayLogo';
import Facebook from './variants/Facebook';
import Instagram from './variants/Instagram';
import NewsLetter from './variants/NewsLetter';

class MobileBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      variant: {
        name: 'facebook',
        title: null,
        description: null,
        newsletterId: null,
        signupSource: null,
      },
    };
  }

  componentDidMount() {
    const setVariantEvent = new EventEmitter();

    setVariantEvent.on('setVariant', (variant) => {
      this.setState(variant);
    });
    window.setVariantEvent = setVariantEvent;
  }

  closeBanner = () => {
    this.setState({ isOpen: false });
  }

  closeBannerDelayed = () => {
    setTimeout(() => this.setState({ isOpen: false }), 5000);
  }

  renderVariant = () => {
    const { variant } = this.state;
    const {
      name: variantName,
      title: variantTitle,
      description: variantDescription,
      newsletterId: variantNewsletterId,
      signupSource: variantSignupSource,
    } = variant;

    if (variantName === 'facebook') {
      return (
        <Facebook
          title={variantTitle}
          description={variantDescription}
        />
      );
    }

    if (variantName === 'instagram') {
      return (
        <Instagram
          title={variantTitle}
          description={variantDescription}
          closeBannerDelayed={this.closeBannerDelayed}
        />
      );
    }

    if (variantName === 'email-signup') {
      return (
        <NewsLetter
          title={variantTitle}
          closeBannerDelayed={this.closeBannerDelayed}
          newsletterId={variantNewsletterId}
          description={variantDescription}
          signupSource={variantSignupSource}
        />
      );
    }

    return <div />;
  }

  render() {
    const { isOpen, variant } = this.state;

    return (
      <div
        className={
          classnames(
            'mobile-banner founders-cond fixed bottom0 b-none bg-white w-100 dn-m dn-l dn-xl dn',
            { 'v-hidden': !isOpen },
          )
        }
        id="mobile-banner-module"
      >
        <div className="mobile-banner__container ml6 mt6 w-100">
          <div className="mobile-banner__logo mb1">
            <TodayLogo
              textColor="black"
              sunriseColor="#FF503C"
              width="80"
              height="12"
            />
          </div>
          <div className="mobile-banner__close absolute block">
            <button
              onClick={this.closeBanner}
              className="bg-white b-none f2"
              type="button"
              id={`mobile-banner-close-${variant.name}`}
            >
              <span className="icon icon-close" />
            </button>
          </div>
          {this.renderVariant()}
          <section className="mobile-banner__dots absolute bottom0 right0" />
        </div>
      </div>
    );
  }
}

export default MobileBanner;
