/* eslint-disable max-len */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import validEmail from 'lib/validEmail';
import { sailthruSignUp } from 'lib/sailthruUtils';


class NewsLetter extends React.Component {
  static propTypes = {
    closeBannerDelayed: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    newsletterId: PropTypes.string,
    signupSource: PropTypes.string,
  };

  static defaultProps = {
    title: 'Get the daily email',
    description: 'Trending stories, celebrity news and all the best of TODAY.',
    newsletterId: 1,
    signupSource: 'banner-mod',
  };

  constructor(props) {
    super(props);
    const { title, description } = this.props;

    this.state = {
      emailAddress: '',
      submitted: false,
      emailValid: true,
      headerText: title,
      bodyText: description,
    };
  }

  handleChange = (e) => {
    e.preventDefault();

    const { value } = e.target;

    this.setState({
      emailAddress: value,
      emailValid: !!value.length && validEmail(value),
    });
  }

  sailthruSuccess = ({ email }) => {
    const { closeBannerDelayed } = this.props;

    this.setState({
      submitted: true,
      headerText: 'You have successfully signed up.',
      bodyText: `${email} has subscribed to the newsletter. Check your inbox for a confirmation email.`,
    });

    closeBannerDelayed();
  }

  sailthruError = () => {}

  handleSubmit = (e) => {
    e.preventDefault();

    const { emailValid, emailAddress } = this.state;
    const { newsletterId, signupSource } = this.props;
    if (!emailValid) return;

    const signupValues = {
      id: emailAddress,
      key: 'email',
      email: emailAddress,
      lists: {
        Master: 1,
      },
      vars: {
        [newsletterId]: 1,
        sign_up_date: new Date().toISOString(),
        referrer: document?.referrer,
        domain: document?.location.href,
      },
      source: signupSource,
      onSuccess: this.sailthruSuccess,
      onError: this.sailthruError,
    };

    sailthruSignUp(signupValues);
  }

  renderEmailInput = () => {
    const { emailAddress, emailValid } = this.state;

    return (
      <form>
        <div className="mobile-banner__input-wrapper dif founders-mono bg-white lh-copy w-100 mb3">
          <input
            name="emailAddress"
            className="mobile-banner__input w-70 v-100 f2 tl"
            onChange={this.handleChange}
            onClick={(e) => e.preventDefault()}
            placeholder="Your Email Address"
            value={emailAddress}
            required="required"
            aria-required="true"
            type="email"
            minLength="3" // https://stackoverflow.com/a/1423203
          />
          <button
            id="mobile-banner-email-signup"
            className={classnames(
              {
                'mobile-banner__email-subscribe-button w-20 v-100 founders-cond white b-none tc ttu': emailValid,
                'mobile-banner__email-subscribe-button--invalid w-20 v-100 founders-cond bg-white b-solid bw-1px tc ttu': !emailValid,
              },
            )}
            data-show_prompt="false"
            data-confirm_howl="false"
            onClick={this.handleSubmit}
            size="normal"
            title="Sign Up"
            type="button"
          >
            sign up
          </button>
        </div>
      </form>
    );
  }

  render() {
    const {
      submitted, headerText, bodyText,
    } = this.state;
    return (
      <div>
        <div className="mobile-banner__header founders-cond f6">
          {headerText}
        </div>
        <div className="mobile-banner__body publico-txt mt2 mb3 f2">
          {bodyText}
        </div>
        {!submitted ? this.renderEmailInput() : null}
        <div className="mobile-banner__footer founders-mono ttu">
          this site is protected by recaptcha
          <>
            <a
              className="mobile-banner__recaptcha-links f1"
              href="https://policies.google.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              &nbsp;
              Privacy Policy
            </a>
            &nbsp;|&nbsp;
            <a
              className="mobile-banner__recaptcha-links f1"
              href="https://policies.google.com/terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>
          </>
        </div>
      </div>
    );
  }
}

export default NewsLetter;
