import React from 'react';
import PropTypes from 'prop-types';

import ArticleBody from 'components/Article/ArticleBody';

import { article as ArticlePropType } from 'lib/CustomPropTypes';

import { MainMedia } from './MainMedia';
import { MediaCaption } from './MediaCaption';
import { Heading } from './Heading';
import { DuetLayout } from './DuetLayout';

import styles from './styles.module.scss';

export function ArticleDuet({
  article,
  path,
  vertical,
  isChromeless,
  showCreatedDate,
  getRightRailAdConfig,
  showUserActions,
  shouldRenderRightRailTaboola,
}) {
  const { primaryMedia } = article;

  return (
    <DuetLayout
      hasMainMedia={primaryMedia?.type === 'embeddedImage'}
      mainMedia={(
        <MainMedia primaryMedia={primaryMedia} />
      )}
      mediaCaption={(
        <MediaCaption primaryMedia={primaryMedia} />
      )}
      headingContent={(
        <Heading article={article} vertical={vertical} className="article-duet-layout--heading" />
      )}
      mainContent={(
        <ArticleBody
          vertical={vertical}
          article={article}
          index={0}
          path={path}
          isChromeless={isChromeless}
          showCreatedDate={showCreatedDate}
          shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
          shouldRenderTaboolaFeed
          getRightRailAdConfig={getRightRailAdConfig}
          showUserActions={showUserActions}
          showBylineTimestamp
          showInlineByline
          className={styles.articleDuetBody}
        />
      )}
    />
  );
}

ArticleDuet.propTypes = {
  article: ArticlePropType.isRequired,
  isChromeless: PropTypes.bool,
  path: PropTypes.string,
  showCreatedDate: PropTypes.bool,
  vertical: PropTypes.string.isRequired,
  getRightRailAdConfig: PropTypes.func,
  showUserActions: PropTypes.bool,
  shouldRenderRightRailTaboola: PropTypes.func,
};

ArticleDuet.defaultProps = {
  isChromeless: false,
  path: '',
  showCreatedDate: true,
  getRightRailAdConfig: null,
  showUserActions: true,
  shouldRenderRightRailTaboola: null,
};

