import React from 'react';
import Head from 'next/head';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import jwt from 'jsonwebtoken';
import getConfig from 'next/config';

import {
  fetchRelatedArticles, loadArticle, loadMostPopularList, getStartTodayArticlePermissions,
} from 'redux/modules/article';
import { bulkUpdate as navUpdateAction } from 'redux/modules/navbar';
import { setPageView, setView, setChromeless } from 'redux/modules/shared';

import BTE from 'lib/BTE';
import {
  MOBILE_ACQUISITION_BANNER,
  NEWSLETTER_SIGNUP_POPUP_MODULE,
  SHOW_USER_ACTIONS,
  ARTICLE_HERO_LAYOUT,
  MOST_POPULAR_MODULE_BRAND_CONFIG,
  RELATED_ARTICLE_BOTTOM_RECIRC,
} from 'lib/brandFeatures';
import { BREAKING_NEWS_FLAGS } from 'lib/breakingNews';
import { ArticleContext, FeatureFlagContext } from 'lib/ContextTypes';
import { article as ArticlePropType } from 'lib/CustomPropTypes';
import { getVideosFromArticlePayload } from 'lib/getVideosFromPayload';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { getPathName } from 'lib/getPathName';
import LegacyIDService from 'lib/LegacyIDService';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { sectionContainSlug, extractTermSlugsAsCommaSeparatedList, isElectionsTaxonomy } from 'lib/taxonomy';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { isShellArticle } from 'lib/article';
import { VIEW } from 'lib/view';

import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import { getTaboolaRecoReelEnabled } from 'components/Article/getSections/insertTaboolaRecoReel';
import ArticleHero from 'components/Article/ArticleHero';
import ArticleBody from 'components/Article/ArticleBody';
import { ErrorBoundary } from 'components/ErrorBoundary';
import IntObserver from 'components/IntObserver';
import PopupModule from 'components/NewsletterSignup/PopupModule';
import MobileBanner from 'components/MobileBanner';
import { ArticleMetadata, GlobalMetadata } from 'components/PageMetadata';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { EventDayRecirc } from 'components/EventDayRecirc';
import ScrollingAnalytics from 'components/ScrollingAnalytics';
import { TopicSubNav } from 'components/TopicSubNav';
import IconfontStyleTag from 'components/IconfontStyleTag';
import UniversalCheckout from 'components/UniversalCheckout';
import { ArticleDuet } from 'components/ArticleDuet';
import { CommerceAmazonContentInsights } from 'components/Commerce/AmazonContentInsights';
import { VideoObjectLDScripts } from 'components/VideoObjectLDScripts';
import { isStartTodayUrl } from 'lib/startTodayUtils';

import ErrorPage from '../_error';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import styles from './styles.module.scss';
import globalContainerStyles from '../globalContainerStyles.module.scss';

const block = 'article';
const pageView = 'article';
const startTodayAppPageView = 'start-today-article';
const externalLink = 'externalLink';

/**
 * Takes some args and returns whether or not to show the timestamp in the byline
 * area of the ArticleBody. Exported for testing purposes
 * @param {DateTime} date Object containing the content's date metadata
 * @param {boolean} breakingNews If the content is marked as breaking news
 * @param {string} flag Text of the flag entered by editors (e.g. 'BREAKING NEWS')
 * @returns {boolean}
 */

export const getShowBylineTimestamp = (date, breakingNews, flag) => {
  // Timestamps can also appear in the ArticleHero's LiveFlag for "Live Blogs" or
  // "Breaking News Articles" with specific flag labels
  const isBreakingNewsFlagged = breakingNews
    && (
      flag === BREAKING_NEWS_FLAGS['BREAKING NEWS']
      || flag === BREAKING_NEWS_FLAGS.DEVELOPING
    );

  const articleDatePubished = date?.publishedAt;
  const articleDateCreated = date?.createdAt;
  const isUpdated = articleDateCreated !== articleDatePubished;

  // In an initially posted LiveBlogs or when specific flag labels are active the Timestamp is
  // only rendered in the ArticleHero instead of ArticleBody
  const isUpdatedBreakingNewsFlagged = isBreakingNewsFlagged && isUpdated;

  return isUpdatedBreakingNewsFlagged || !isBreakingNewsFlagged;
};

export const navbarConfig = {
  theme({ content, vertical }) {
    if (Object.keys(content).length) {
      const {
        source,
        taxonomy: { sections },
      } = content;

      if ((content?.presentation?.style ?? false) === 'DUET') {
        if (vertical === 'today') return NAVBAR_THEME.LIGHT;
        return NAVBAR_THEME.DARK;
      }

      const isShoppingSection = sectionContainSlug(sections, 'shopping');
      if (isShoppingSection) {
        return NAVBAR_THEME.LIGHT;
      }

      const getShoppingSourceName = source?.organization?.name ?? false;
      if (vertical === 'news' && getShoppingSourceName === 'StackCommerce') {
        return NAVBAR_THEME.VERTICAL;
      }
    }

    if (['today'].indexOf(vertical) >= 0) {
      return NAVBAR_THEME.LIGHT;
    }
    return NAVBAR_THEME.VERTICAL;
  },
  headline: ({ content }) => content?.headline ?? null,
  activeAt: ({ vertical, navbarContent }) => {
    const hasElectionsNav = isElectionsTaxonomy(navbarContent?.taxonomy?.primaryTopic?.slug);
    return (vertical === 'today' && !isShellArticle(navbarContent))
    || (navbarContent?.subnav && !hasElectionsNav)
      ? null
      : 160;
  },
  showShortcuts: ({ content }) => {
    const primaryTopic = content?.taxonomy?.primaryTopic;
    const electionYears = ['2020', '2022'];
    if (primaryTopic) {
      return !electionYears.some((year) => (
        [
          `${year}-election`,
          `elecciones-estados-unidos-${year}`,
        ].includes(extractTermSlugsAsCommaSeparatedList(primaryTopic))
      ));
    }
    return true;
  },
};

const mapStateToProps = ({
  article, shared, router, navbar: navStore,
}) => ({
  articles: article.content,
  vertical: shared.vertical,
  isChromeless: shared.isChromeless,
  view: shared.view,
  path: router.path,
  active: navStore.active,
  mostPopularStoryListObject: article.mostPopularStoryList,
  relatedArticles: article.relatedArticles,
});

const mapActionsToProps = (dispatch) => ({
  navUpdate: (theme) => dispatch(navUpdateAction(theme)),
});

class ArticlePage extends React.Component {
  static contextType = FeatureFlagContext;

  static getInitialProps = async (ctx) => {
    const {
      req: {
        url,
        headers: {
          authorization,
        } = {},
        params: {
          id: rawID,
        },
      } = {},
      res: {
        locals: {
          getLaunchDarklyFlag,
          vertical,
          data: {
            curatedList: eventDayList,
          } = {},
        },
      } = {},
      store,
    } = ctx;

    let promises;
    const id = LegacyIDService.article(rawID);
    const isStartTodayAppRoute = isStartTodayUrl(url);
    const token = authorization?.split(' ')[1] ?? '';

    // If the article is a Start Today article,
    // we need to make an additional request to get the article content
    if (isStartTodayAppRoute) {
      const {
        serverRuntimeConfig: { JWT_PUBLIC_KEY },
      } = getConfig();

      const forbiddenResponse = {
        navigating: false,
        pageView,
        isStartTodayAppRoute,
        statusCode: 403,
        eventDayList,
      };

      // If JWT_PUBLIC_KEY is not set, return a 403
      if (!JWT_PUBLIC_KEY) {
        return forbiddenResponse;
      }

      const additionalRequestData = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Verify if token expired and vertical is today.
      // This is a quicker way to return a 403 than calling bento api.
      try {
        const decodedToken = jwt.verify(token, Buffer.from(JWT_PUBLIC_KEY, 'base64').toString('utf-8'));
        const { exp, brand } = decodedToken;

        const isToday = brand === 'today';
        const isValidToken = exp >= Math.floor(Date.now() / 1000);

        // If the token is not for the Today brand
        // or is expired, return a 403
        if (!isToday || !isValidToken) {
          return forbiddenResponse;
        }
      } catch (error) {
        // If the token is invalid, return also a 403
        return forbiddenResponse;
      }

      // Call the bento api to check if token has access to the article
      const { payload: { data, status } = {} } = await store.dispatch(
        getStartTodayArticlePermissions(id, { additionalRequestData }),
      ) ?? {};

      // If the token does not have access to the article, return a 403
      if (!data) {
        return { ...forbiddenResponse, statusCode: status };
      }

      promises = [
        store.dispatch(setPageView(startTodayAppPageView)),
        store.dispatch(setView(VIEW.START_TODAY_APP)),
        store.dispatch(setChromeless(true)),
        store.dispatch(loadArticle(id, { additionalRequestData })),
      ];


    // Otherwise, we can just load the article content and set the page view
    } else {
      promises = [
        store.dispatch(loadArticle(id)),
        store.dispatch(setPageView(pageView)),
      ];
    }

    const shouldRenderMostPopularStoryList = getFeatureConfigForBrand(
      MOST_POPULAR_MODULE_BRAND_CONFIG,
      vertical,
    );

    let isMostPopularModuleEnabled = false;
    try {
      isMostPopularModuleEnabled = await getLaunchDarklyFlag('is-most-popular-module-enabled');
    } catch (e) {
      console.error('Could not retrieve feature flag for Most Popular Module. Error is logged directly below.');
      console.error(e);
    }

    const mostPopularQueryIdsByVertical = { news: 'nccl00011223344', msnbc: 'nccl00088112233', today: 'tdcl00055667799' };

    if (isMostPopularModuleEnabled && shouldRenderMostPopularStoryList) {
      const curatedListId = mostPopularQueryIdsByVertical[vertical];
      promises.push(store.dispatch(loadMostPopularList(curatedListId)));
    }

    await Promise.all(promises);

    const { article } = store.getState();
    const articleContent = article?.content?.[0];
    const articleSubType = articleContent?.subType;
    const primaryURL = articleContent?.url?.primary ?? '';
    const headline = articleContent?.headline ?? {};
    const articleId = articleContent?.id ?? '';
    const primaryLabelTaxonomyPath = articleContent?.taxonomy?.primaryLabel?.path;
    const unibrowTaxonomyPath = articleContent?.unibrow?.taxonomyPath;

    if (articleSubType === externalLink) {
      ctx.res.redirect(primaryURL);
    }

    const statusCode = article?.error?.status ?? 200;

    await store.dispatch(navUpdateAction({
      shareUrl: getPathName(primaryURL),
      headline,
    }));

    setLinkHeaders(ctx.res, vertical);

    const taxonomyPath = primaryLabelTaxonomyPath ?? unibrowTaxonomyPath;

    const relatedArticleBottomRecirc = getFeatureConfigForBrand(
      RELATED_ARTICLE_BOTTOM_RECIRC,
      vertical,
    );

    const shouldShowRecirc = relatedArticleBottomRecirc && !articleContent?.ecommerceEnabled;

    if (shouldShowRecirc) {
      const params = {
        taxonomyPath,
        currentArticle: articleId,
        queryLimit: 10,
      };
      await store.dispatch(fetchRelatedArticles(params));
    }

    return {
      navigating: false,
      pageView,
      isStartTodayAppRoute,
      statusCode,
      eventDayList,
    };
  };

  static propTypes = {
    isStartTodayAppRoute: PropTypes.bool,
    articles: PropTypes.arrayOf(ArticlePropType).isRequired,
    relatedArticles: PropTypes.arrayOf(ArticlePropType),
    vertical: PropTypes.string.isRequired,
    isChromeless: PropTypes.bool,
    view: PropTypes.string,
    path: PropTypes.string,
    active: PropTypes.bool,
    navUpdate: PropTypes.func.isRequired,
    eventDayList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    layout: servicesLayoutPropType.isRequired,
    statusCode: PropTypes.number.isRequired,
    mostPopularStoryListObject: PropTypes.object,
  };

  static defaultProps = {
    isChromeless: false,
    view: null,
    path: '',
    active: false,
    mostPopularStoryListObject: {},
    eventDayList: null,
    relatedArticles: null,
    isStartTodayAppRoute: false,
  };

  componentDidUpdate(prevProps) {
    const { active: prevActive } = prevProps;
    const { articles, active, navUpdate } = this.props;

    // UpdateTheme if active on StackCommerce articles
    if (
      (articles?.[0]?.source?.organization?.name ?? false) === 'StackCommerce'
    ) {
      if (!prevActive && active) {
        navUpdate({ theme: NAVBAR_THEME.VERTICAL });
      } else if (prevActive && !active) {
        navUpdate({ theme: NAVBAR_THEME.LIGHT });
      }
    }
  }

  handleMainContentError = () => <ErrorPage statusCode={500} />;

  onView = (e) => {
    if (e?.[0]?.isIntersecting) {
      // eslint-disable-next-line react/destructuring-assignment
      const id = this.props?.articles?.[0]?.id;
      BTE.trigger('articleInView', id);
    }
  };

  render() {
    const {
      'taboola-reco-reel': taboolaRecoReelEnabledLD,
      'shoppable-articles': shoppableArticlesEnabled,
    } = this.context;

    const {
      articles,
      isChromeless,
      view,
      layout,
      path,
      statusCode,
      vertical,
      eventDayList,
      mostPopularStoryListObject,
      relatedArticles,
      isStartTodayAppRoute,
    } = this.props;
    const { 'topic-subnav': isTopicSubnavEnabled } = this.context;

    const mostPopularStoryListItems = mostPopularStoryListObject?.content?.items;

    if (parseInt(statusCode, 10) >= 400) {
      return (
        <ErrorPage statusCode={statusCode} layout={layout} />
      );
    }

    const article = articles?.length && articles[0];

    const {
      id: articleID,
      breakingNews,
      date,
      ecommerceEnabled,
      flag,
      taxonomy: {
        primaryLabel = {},
        allTerms = [],
      },
    } = article;
    const primaryTaxonomyName = primaryLabel?.name;
    const { id } = article;

    const isShopThisList = allTerms.some(({ path: p }) => p === 'today/label/shop-this-list');

    const isShoppable = shoppableArticlesEnabled && ecommerceEnabled && isShopThisList;

    const hasElectionsNav = isElectionsTaxonomy(article?.taxonomy?.primaryTopic?.slug);
    const shouldShowSubNav = Boolean(article?.subnav && !hasElectionsNav && isTopicSubnavEnabled);


    const displayMobileBanner = getFeatureConfigForBrand(
      MOBILE_ACQUISITION_BANNER,
      vertical,
    );
    const shouldNewsletterSignupPopupModuleRender = getFeatureConfigForBrand(
      NEWSLETTER_SIGNUP_POPUP_MODULE,
      vertical,
    );

    const heroLayoutType = getFeatureConfigForBrand(
      ARTICLE_HERO_LAYOUT,
      vertical,
    );

    // 100000% top margin to pretend the viewport is "infinitely" tall, so you will
    // still be considered "past" the top of the article
    // -100% bottom margin to get the 0 threshold to trigger only once the top of the article
    // passes the top of the viewport
    const intObsRootMargin = '100000% 0px -100% 0px';

    const getRightRailAdConfig = vertical !== 'select' ? null : () => ({ display: false });

    const shouldRenderRightRailTaboola = vertical !== 'select' ? null : () => false;

    const showBylineTimestamp = getShowBylineTimestamp(date, breakingNews, flag);

    const userActionConfig = getFeatureConfigForBrand(SHOW_USER_ACTIONS, vertical);
    let showUserActions = !!userActionConfig;
    if (userActionConfig?.hideFromChromeless) {
      showUserActions = !isChromeless;
    }
    // hide social icons in Start Today App view
    if (isStartTodayAppRoute) {
      showUserActions = false;
    }

    const taboolaRecoReelEnabled = taboolaRecoReelEnabledLD && getTaboolaRecoReelEnabled(vertical);

    const { 'primary-media-banner': primaryMediaBannerEnabled } = this.context;

    const content = (
      <ErrorBoundary errorHandler={this.handleMainContentError}>
        <IntObserver
          rootMargin={intObsRootMargin}
          threshold={0}
          callback={this.onView}
        >
          <ScrollingAnalytics contentType="article" contentUrl={article.url.primary}>
            <ArticleContext.Provider value={article}>
              <GlobalMetadata hidden={isStartTodayAppRoute} />
              <ArticleMetadata article={article} vertical={vertical} />
              {shouldShowSubNav && (
                <TopicSubNav
                  titleText={article.subnav.headline?.primary}
                  titleLink={article.subnav.externalUrl}
                  linkItems={article.subnav.content?.items}
                  isActiveLink={(item) => item.id === id}
                  logo={article.subnav.teaseImage}
                />
              )}
              <article
                key={`articleWrapper-${article.id}`}
                className={classNames(styles.article, block)}
              >
                {shouldNewsletterSignupPopupModuleRender && <PopupModule />}
                {displayMobileBanner && <MobileBanner />}
                {article.presentation?.style === 'DUET'
                  ? (
                    <ArticleDuet
                      article={article}
                      path={path}
                      vertical={vertical}
                      isChromeless={isChromeless}
                      showCreatedDate
                      getRightRailAdConfig={getRightRailAdConfig}
                      showUserActions={showUserActions}
                      shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
                    />
                  )
                  : (
                    <>
                      <ArticleHero
                        content={article}
                        isPresentationStyleWide={article.presentation?.style === 'WIDE'}
                        isChromeless={isChromeless}
                        heroLayoutType={heroLayoutType}
                        primaryMediaBannerEnabled={primaryMediaBannerEnabled}
                        vertical={vertical}
                        isShoppable={isShoppable}
                      />
                      <ArticleBody
                        vertical={vertical}
                        article={article}
                        index={0}
                        path={path}
                        isChromeless={isChromeless}
                        view={view}
                        isShoppable={isShoppable}
                        getRightRailAdConfig={getRightRailAdConfig}
                        shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
                        taboolaRecoReelEnabled={taboolaRecoReelEnabled}
                        showUserActions={showUserActions}
                        showBylineTimestamp={showBylineTimestamp}
                        mostPopularStoryListItems={mostPopularStoryListItems}
                        relatedArticles={relatedArticles}
                        primaryTaxonomyName={primaryTaxonomyName}
                      />
                    </>
                  )}
              </article>
            </ArticleContext.Provider>
          </ScrollingAnalytics>
        </IntObserver>
      </ErrorBoundary>
    );

    const wrappedContent = isChromeless ? (
      content
    ) : (
      <HeaderAndFooter
        layout={layout}
        contentAboveHeader={(
          article?.ecommerceEnabled && eventDayList?.content?.items?.length > 0
          && (
            <EventDayRecirc
              headline={eventDayList.headline}
              externalUrl={eventDayList.externalUrl}
              content={eventDayList.content}
            />
          )
        )}
        isNavbarSticky={!shouldShowSubNav}
      >
        {content}
      </HeaderAndFooter>
    );

    const allVideos = getVideosFromArticlePayload(article);

    return (
      <>
        <Head>
          <IconfontStyleTag />
        </Head>
        <VideoObjectLDScripts videos={allVideos} vertical={vertical} />
        <ThemesCssBundle vertical={vertical} />

        <div
          className={classNames(
            globalContainerStyles.container,
            'bg-knockout-primary',
          )}
          id="content"
        >
          {wrappedContent}
        </div>
        <AnalyticsLaunchScripts isChromeless={isChromeless} pageID={articleID} />
        <AdsBundle isArticle vertical={vertical} />
        <UniversalCheckout vertical={vertical} />
        <CommerceAmazonContentInsights />
      </>
    );
  }
}

export default navbar(navbarConfig)(
  connect(mapStateToProps, mapActionsToProps)(ArticlePage),
);

// exported for testing
export { ArticlePage as UnwrappedArticlePage };
